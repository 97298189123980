import { memo, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../../components/VirtualizedTable';

const DATES_COLORS = {
  preForecast: 'bg-light',
  forecast: 'bo-table-row-bg-yellow',
  postForecast: 'bg-white',
  postNextPayday: 'bg-light',
};

function NewObjectCashflowSubTab({ dates }) {
  const forecastDates = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(dates).filter(
          ([, value]) => value.type !== 'preForecast' && value.type !== 'postNextPayday',
        ),
      ),
    [dates],
  );

  const getCashflowByDate = useCallback(
    (direction, cashflowKey) =>
      Object.fromEntries(
        Object.entries(forecastDates).map(([key, value]) => [
          key,
          value.cashflow[direction][cashflowKey],
        ]),
      ),
    [forecastDates],
  );

  const tableRows = useMemo(
    () => [
      {
        uuid: crypto.randomUUID(),
        title: 'Pending Debit',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'pending'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'Predicted Recurring Debit',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'recurring'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'BridgeOver Funding Repayments',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'funding'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'Predicted Variable Debit',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'variable'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'Pending Credit',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'pending'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'Predicted Recurring Credit',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'recurring'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'BridgeOver Funding',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'funding'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'Predicted Variable Credit',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'variable'),
      },
      {
        uuid: crypto.randomUUID(),
        title: 'Predicted Payroll',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'payroll'),
      },
      {
        uuid: crypto.randomUUID(),
        title: `Predicted Balance (Opening=${
          Object.values(dates).findLast(value => value.type === 'preForecast').cashflow.eodBalance
        })`,
        ...Object.fromEntries(
          Object.entries(forecastDates).map(([key, value]) => [key, value.cashflow.eodBalance]),
        ),
      },
      {
        uuid: crypto.randomUUID(),
        title: "Predicted 'High probability' Balance (only recurring with prob>50%)",
        ...Object.fromEntries(
          Object.entries(forecastDates).map(([key, value]) => [
            key,
            value.cashflow.eodHighProbBalance,
          ]),
        ),
      },
    ],
    [getCashflowByDate, forecastDates, dates],
  );

  const tableColumns = useMemo(
    () => [
      {
        key: 'title',
        width: 400,
      },
      ...Object.entries(forecastDates).map(([key]) => ({
        key,
        width: 100,
      })),
    ],
    [forecastDates],
  );

  const headerConfig = useMemo(() => {
    const payrollCycles = Object.values(forecastDates).reduce((acc, val) => {
      if (acc[val.payrollCycle]) {
        acc[val.payrollCycle] += 1;
      } else {
        acc[val.payrollCycle] = 1;
      }

      return acc;
    }, {});

    return [
      [
        { header: '', colspan: 1 },
        ...Object.entries(forecastDates).map(([key, value]) => ({
          header: key,
          colspan: 1,
          className: DATES_COLORS[value.type],
        })),
      ],
      [
        { header: '', colspan: 1 },
        ...Object.values(forecastDates).map(value => ({
          header: value.weekday,
          colspan: 1,
          className: DATES_COLORS[value.type],
        })),
      ],
      [
        { header: '', colspan: 1 },
        ...Object.entries(payrollCycles).map(([key, value]) => ({ header: key, colspan: value })),
      ],
    ];
  }, [forecastDates]);

  const formatCellConditionally = useCallback(
    (row, itemKey) => {
      if (itemKey === 'title' && row.direction) {
        return {
          backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
        };
      }

      if (
        forecastDates[itemKey] &&
        (forecastDates[itemKey].type === 'preForecast' ||
          forecastDates[itemKey].type === 'postNextPayday')
      ) {
        return { backgroundColor: '#F8F9FA' };
      }

      if (row[itemKey] !== null && row[itemKey] !== undefined && row.direction) {
        return {
          backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
        };
      }

      return {};
    },
    [forecastDates],
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedTable
          tableRows={tableRows}
          tableColumns={tableColumns}
          headerConfig={headerConfig}
          headerHeight={24}
          rowHeight={24}
          headerLeftBorder
          customHoverClass="bo-table-alt-hover-bg"
          cellPaddingLeftSize={2}
          width={width}
          height={height}
          formatCellConditionally={formatCellConditionally}
          rowKey="uuid"
        />
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectCashflowSubTab);
