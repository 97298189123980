import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner } from '../../../../../components/Spinner';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../components/VirtualizedTable';
import { Dropdown, DropdownItem, DropdownMenu, Input, Label, Popover } from 'reactstrap';
import { PAYROLL_FREQUENCIES } from '../../../../../constants/consumers';
import moment from 'moment';
import { useCustomContextMenu } from '../../../../../hooks/useCustomContextMenu';

function NewObjectRecurringStreamsTab({
  consumerId,
  profileJson,
  getProfileJson,
  refreshTab,
  setRefreshTab,
  setGlobalRecurringStreamId,
  toggleProfileActiveTab,
}) {
  const [spinner, setSpinner] = useState(false);
  const [showInactiveStreams, setShowInactiveStreams] = useState(false);

  const { popoverTarget, contextMenuKey, updateContextMenuPosition, toggleContextMenu } =
    useCustomContextMenu('popover_recurring_streams_menu');
  const [prospectRecurringId, setProspectRecurringId] = useState(null);

  const isActive = useCallback((frequency, lastDate, forecastStartDate) => {
    const mLastDate = moment(lastDate);
    const mForecastStartDate = moment(forecastStartDate);

    if (
      (frequency === 'WEEKLY' &&
        mLastDate.isBefore(mForecastStartDate.clone().subtract(2, 'weeks'))) ||
      (frequency === 'MONTHLY' &&
        mLastDate.isBefore(mForecastStartDate.clone().subtract(2, 'months'))) ||
      (frequency === 'BIWEEKLY' &&
        mLastDate.isBefore(mForecastStartDate.clone().subtract(4, 'weeks'))) ||
      (frequency === 'SEMI_MONTHLY' &&
        mLastDate.isBefore(mForecastStartDate.clone().subtract(1, 'month'))) ||
      (frequency === 'QUARTERLY' &&
        mLastDate.isBefore(mForecastStartDate.clone().subtract(4, 'months')))
    ) {
      return false;
    }

    return true;
  }, []);

  const recurringStreams = useMemo(() => {
    if (profileJson?.profile?.recurringStreams.length > 0) {
      return [...profileJson.profile.recurringStreams]
        .sort((a, b) => b.score - a.score)
        .filter(x =>
          showInactiveStreams
            ? true
            : isActive(x.frequency, x.lastDate, profileJson.prediction.forecast.firstForecastDate),
        );
    }

    return [];
  }, [profileJson, showInactiveStreams, isActive]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'direction',
        header: 'Direction',
        width: 100,
        renderValue: value => (value === 'OUTFLOW' ? 'Outflow' : 'Inflow'),
      },
      {
        key: 'description',
        header: 'Description',
        width: 240,
      },
      {
        key: 'frequency',
        header: 'Frequency',
        width: 120,
        renderValue: value => PAYROLL_FREQUENCIES[value],
      },
      {
        key: 'lastDate',
        header: 'Last Transaction',
        width: 200,
        renderValue: (_, row) => (
          <div>
            {`$${row.lastAmount.toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`}
            <strong className="mx-1">at</strong>
            {moment(row.lastDate).format('YYYY-MM-DD')}
          </div>
        ),
      },
      {
        key: 'last3Average',
        header: 'Last 3 Average Amount',
        width: 180,
        renderValue: value =>
          `$${value.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}`,
      },
      {
        key: 'transactionsCount',
        header: 'Count',
        width: 100,
      },
      {
        key: 'score',
        header: 'Score',
        width: 100,
        renderValue: value =>
          `${value.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}`,
      },
      {
        key: 'detectorType',
        header: 'Detector',
        width: 120,
      },
      {
        key: 'daysCountRendered',
        header: 'Cluster Days',
        width: 150,
        renderValue: value => (
          <div>
            {value.split('\n').map(x => (
              <div key={x}>{x}</div>
            ))}
          </div>
        ),
      },
      {
        key: 'metrics',
        header: 'Metrics',
        width: 250,
        renderValue: value => (
          <div>
            {Object.entries(value).map(([key, val]) => (
              <div key={key}>{`${key}: ${val.toLocaleString('en-US')}`}</div>
            ))}
          </div>
        ),
      },
      {
        key: 'category',
        header: 'Category',
        width: 300,
      },
      {
        key: 'numberOfCombinations',
        header: 'Combinations',
        width: 120,
      },
    ],
    [],
  );

  const getRowHeight = useCallback(
    index => {
      const clasterDaysLength = recurringStreams[index].daysCountRendered?.split('\n')?.length || 0;
      const metricsLength = Object.entries(recurringStreams[index].metrics)?.length || 0;

      return Math.max(clasterDaysLength * 18 + 18, metricsLength * 18 + 18, 36);
    },
    [recurringStreams],
  );

  const getRecurringStreams = async () => {
    setSpinner(true);
    await getProfileJson(consumerId);
    setSpinner(false);
  };

  useEffect(() => {
    if (refreshTab === 'recurring_streams') {
      getRecurringStreams();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  const formatCellConditionally = useCallback(
    row => {
      if (
        !isActive(row.frequency, row.lastDate, profileJson.prediction.forecast.firstForecastDate)
      ) {
        return { opacity: 0.6 };
      }

      return {};
    },
    [profileJson, isActive],
  );

  const handleRowRightClick = useCallback(
    (e, row) => {
      e.preventDefault();

      updateContextMenuPosition(e.pageX, e.pageY);
      setProspectRecurringId(row.uuid);
    },
    [updateContextMenuPosition],
  );

  return (
    <Spinner visible={spinner} wrapperStyle={{ height: '100%' }}>
      <div className="h-100">
        <div className="m-3 d-flex gap-2 overflow-hidden">
          <div className="d-flex align-items-center">
            <Input
              className="m-0 position-relative"
              type="checkbox"
              checked={showInactiveStreams}
              onChange={e => setShowInactiveStreams(e.target.checked)}
            />
            <Label className="bo-new-object-text-md ms-1 text-nowrap">Show inactive streams</Label>
          </div>
        </div>
        {popoverTarget && (
          <Popover
            key={contextMenuKey}
            target={popoverTarget}
            isOpen={!!contextMenuKey}
            placement="top-start"
            trigger="legacy"
            toggle={toggleContextMenu}
            offset={[10, 10]}
            fade={false}
            hideArrow
          >
            <Dropdown isOpen toggle={toggleContextMenu}>
              <DropdownMenu className="bo-new-object-dropdown-menu">
                <DropdownItem
                  className="bo-new-object-dropdown-menu-item"
                  onClick={() => {
                    setGlobalRecurringStreamId(prospectRecurringId);
                    toggleProfileActiveTab('transactions');
                  }}
                >
                  Show Transactions
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Popover>
        )}
        <AutoSizer>
          {({ height, width }) => (
            <VirtualizedTable
              tableRows={recurringStreams}
              tableColumns={tableColumns}
              width={width}
              height={height - 62}
              headerTopBorder
              getRowHeight={getRowHeight}
              rowKey="uuid"
              formatCellConditionally={formatCellConditionally}
              handleRowRightClick={handleRowRightClick}
            />
          )}
        </AutoSizer>
      </div>
    </Spinner>
  );
}

export default memo(NewObjectRecurringStreamsTab);
