import { memo, useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../../components/VirtualizedTable';

function NewObjectCashflowSubTab({ forecast }) {
  const getTableRows = useCallback(
    direction => {
      const rows = [];

      if (forecast[direction].pending && forecast[direction].pending.length > 0) {
        rows.push(
          {
            uuid: crypto.randomUUID(),
            title: 'Pending Debit',
            titleBold: true,
            direction,
          },
          ...forecast[direction].pending.map(x => ({
            uuid: crypto.randomUUID(),
            title: `- ${x.description}`,
            direction,
            amount: x.amount,
          })),
        );
      }

      if (forecast[direction].recurring && forecast[direction].recurring.length > 0) {
        rows.push(
          {
            uuid: crypto.randomUUID(),
            title: 'Recurring',
            titleBold: true,
            direction,
          },
          ...forecast[direction].recurring.map(x => ({
            uuid: crypto.randomUUID(),
            title: `- ${x.descrption}`,
            direction,
            amount: x.range,
            probability: `${x.probability}%`,
          })),
        );
      }

      if (forecast[direction].funding && forecast[direction].funding.length > 0) {
        rows.push(
          {
            uuid: crypto.randomUUID(),
            title: 'BridgeOver Funding',
            titleBold: true,
            direction,
          },
          ...forecast[direction].funding.map(x => ({
            uuid: crypto.randomUUID(),
            title: `- ${x.descrption}`,
            direction,
            amount: x.amount,
          })),
        );
      }

      if (forecast[direction].payroll) {
        rows.push({
          uuid: crypto.randomUUID(),
          title: 'Payroll',
          titleBold: true,
          direction,
          amount: forecast[direction].payroll,
        });
      }

      if (forecast[direction].variable) {
        rows.push({
          uuid: crypto.randomUUID(),
          title: 'Variable',
          titleBold: true,
          direction,
          amount: forecast[direction].variable,
        });
      }

      rows.push({
        uuid: crypto.randomUUID(),
        title: direction === 'OUTFLOW' ? 'Total debit' : 'Total credit',
        titleBold: true,
        direction,
        amount: forecast[direction].totalRange,
      });

      return rows;
    },
    [forecast],
  );

  const getTableColumns = useCallback(
    direction => [
      {
        key: 'title',
        header: direction === 'OUTFLOW' ? 'Debit' : 'Credit',
        width: 300,
      },
      {
        key: 'amount',
        header: 'Amount',
        width: 100,
      },
      {
        key: 'probability',
        header: 'Probability',
        width: 100,
      },
    ],
    [],
  );

  const formatCellConditionally = useCallback((row, itemKey) => {
    if (itemKey === 'title' && row.titleBold) {
      return {
        fontWeight: 700,
      };
    }

    return {};
  }, []);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          <VirtualizedTable
            tableRows={getTableRows('OUTFLOW')}
            tableColumns={getTableColumns('OUTFLOW')}
            headerHeight={24}
            rowHeight={24}
            headerLeftBorder
            width={width}
            height={height / 2}
            customHoverClass="bo-table-alt-hover-bg"
            tableContainerStyle={{ backgroundColor: '#f6c142' }}
            formatCellConditionally={formatCellConditionally}
            rowKey="uuid"
          />
          <VirtualizedTable
            tableRows={getTableRows('INFLOW')}
            tableColumns={getTableColumns('INFLOW')}
            headerHeight={24}
            rowHeight={24}
            headerTopBorder
            headerLeftBorder
            width={width}
            height={height / 2}
            customHoverClass="bo-table-alt-hover-bg"
            tableContainerStyle={{ backgroundColor: '#a0cd63' }}
            formatCellConditionally={formatCellConditionally}
            rowKey="uuid"
          />
        </>
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectCashflowSubTab);
