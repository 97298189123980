import { memo, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedTable from '../../../../../../components/VirtualizedTable';

const DATES_COLORS = {
  preForecast: 'bg-light',
  forecast: 'bo-table-row-bg-yellow',
  postForecast: 'bg-white',
  postNextPayday: 'bg-light',
};

function NewObjectFlowsSubTab({ dates, funding, payroll, pending, recurring, variable }) {
  const tableRows = useMemo(
    () => [
      ...pending.OUTFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        title: x.description,
        type: 'Pending',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      ...recurring.OUTFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        title: x.description,
        type: x.type,
        amounts: x.range,
        ...Object.fromEntries(
          Object.entries(x.dates).map(([key, value]) => [
            key,
            { amount: `${value}%`, status: x.status },
          ]),
        ),
      })),
      ...funding.OUTFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        title: x.description,
        type: 'Advance',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      {
        uuid: crypto.randomUUID(),
        direction: 'OUTFLOW',
        title: variable.OUTFLOW.description,
        type: '',
        amounts: '',
        ...Object.fromEntries(
          Object.entries(variable.OUTFLOW.dates).map(([key, value]) => [key, { amount: value }]),
        ),
      },
      {
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        title: 'Payroll',
        type: '',
        amounts: '',
        ...Object.fromEntries(
          Object.entries(payroll).map(([key, value]) => [
            key,
            { amount: value.amount, status: value.status },
          ]),
        ),
      },
      ...pending.INFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        title: x.description,
        type: 'Pending',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      ...recurring.INFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        title: x.description,
        type: x.type,
        amounts: x.range,
        ...Object.fromEntries(
          Object.entries(x.dates).map(([key, value]) => [
            key,
            { amount: `${value}%`, status: x.status },
          ]),
        ),
      })),
      ...funding.INFLOW.map(x => ({
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        title: x.description,
        type: 'Advance',
        amounts: x.amount,
        [x.date]: { amount: x.amount },
      })),
      {
        uuid: crypto.randomUUID(),
        direction: 'INFLOW',
        title: variable.INFLOW.description,
        type: '',
        amounts: '',
        ...Object.fromEntries(
          Object.entries(variable.INFLOW.dates).map(([key, value]) => [key, { amount: value }]),
        ),
      },
    ],
    [pending, recurring, funding, variable, payroll],
  );

  const tableColumns = useMemo(
    () => [
      {
        key: 'title',
        width: 240,
        renderValue: value => <div className="text-truncate">{value}</div>,
      },
      {
        key: 'type',
        width: 100,
      },
      {
        key: 'amounts',
        width: 100,
      },
      ...Object.keys(dates).map(key => ({
        key,
        width: 90,
        renderValue: value => (value ? value.amount : null),
      })),
    ],
    [dates],
  );

  const headerConfig = useMemo(() => {
    const payrollCycles = Object.values(dates).reduce((acc, val) => {
      if (acc[val.payrollCycle]) {
        acc[val.payrollCycle] += 1;
      } else {
        acc[val.payrollCycle] = 1;
      }

      return acc;
    }, {});

    return [
      [
        { header: '', colspan: 1 },
        { header: 'Type', colspan: 1 },
        { header: 'Amounts', colspan: 1 },
        ...Object.entries(dates).map(([key, value]) => ({
          header: key,
          colspan: 1,
          className: DATES_COLORS[value.type],
        })),
      ],
      [
        { header: '', colspan: 3 },
        ...Object.values(dates).map(value => ({
          header: value.weekday,
          colspan: 1,
          className: `${DATES_COLORS[value.type]}`,
        })),
      ],
      [
        { header: '', colspan: 3 },
        ...Object.entries(payrollCycles).map(([key, value]) => ({ header: key, colspan: value })),
      ],
    ];
  }, [dates]);

  const formatCellConditionally = useCallback(
    (row, itemKey) => {
      if (itemKey === 'title' || itemKey === 'type' || itemKey === 'amounts') {
        return {
          backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
        };
      }

      if (dates[itemKey]) {
        if (row[itemKey]?.amount !== null && row[itemKey]?.amount !== undefined) {
          if (row[itemKey]?.status && row[itemKey]?.status !== 'expected') {
            return {
              backgroundColor: row.direction === 'OUTFLOW' ? '#ffd778' : '#c9f78d',
            };
          }

          return {
            backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
          };
        }

        if (dates[itemKey].type === 'preForecast' || dates[itemKey].type === 'postNextPayday') {
          return { backgroundColor: '#F8F9FA' };
        }
      }

      return {};
    },
    [dates],
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedTable
          tableRows={tableRows}
          tableColumns={tableColumns}
          headerConfig={headerConfig}
          headerHeight={24}
          rowHeight={24}
          headerLeftBorder
          customHoverClass="bo-table-alt-hover-bg"
          cellPaddingLeftSize={2}
          width={width}
          height={height}
          formatCellConditionally={formatCellConditionally}
          rowKey="uuid"
        />
      )}
    </AutoSizer>
  );
}

export default memo(NewObjectFlowsSubTab);
