import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { areEqual, VariableSizeGrid } from 'react-window';

const SCROLLBAR_SIZE = 10;

const HeaderCell = memo(({ data, columnIndex, rowIndex, style }) => {
  const {
    headerData,
    cellPaddingLeftSize = 3,
    headerTopBorder,
    headerLeftBorder,
    addWidthToLastRow,
  } = data;

  const cellData = headerData[rowIndex][columnIndex];

  return (
    <div
      key={columnIndex}
      style={{
        ...style,
        width: addWidthToLastRow ? style.width + SCROLLBAR_SIZE : style.width,
      }}
      className={classNames(
        `bo-table-cell fw-bold ps-${cellPaddingLeftSize} pe-1 overflow-hidden border-bottom`,
        headerLeftBorder && columnIndex !== 0 && !cellData.isPlaceholder && 'border-start',
        headerTopBorder && 'border-top',
        cellData.className,
      )}
    >
      {cellData && !cellData.isPlaceholder ? cellData.header : ''}
    </div>
  );
}, areEqual);

const Cell = memo(
  ({
    data: {
      tableRows,
      tableColumns,
      cellPaddingLeftSize = 3,
      rowClickable,
      activeRowIndex,
      setActiveRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      setHoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
    },
    columnIndex,
    rowIndex,
    style,
  }) => {
    let newStyle = style;

    const { renderValue, key } = tableColumns[columnIndex];

    const value = tableRows[rowIndex][key];

    let className = `bo-table-cell ps-${cellPaddingLeftSize} pe-1 overflow-hidden`;

    className +=
      hoovering && hoveredRowIndex === rowIndex
        ? ` ${customHoverClass || 'bo-table-hover-bg'}`
        : '';
    className += activeRowIndex === rowIndex ? ' bo-table-active-bg' : '';

    if (formatCellConditionally) {
      newStyle = {
        ...newStyle,
        ...formatCellConditionally(tableRows[rowIndex], tableColumns[columnIndex].key),
      };
    }

    return (
      /* eslint-disable */
      <div
        key={columnIndex}
        style={newStyle}
        onClick={
          rowClickable
            ? () => setActiveRowIndex(prevIndex => (prevIndex === rowIndex ? null : rowIndex))
            : undefined
        }
        onMouseOver={hoovering ? () => setHoveredRowIndex(rowIndex) : undefined}
        onContextMenu={e => {
          if (handleRowRightClick) {
            handleRowRightClick(e, tableRows[rowIndex]);
          }
        }}
        className={className}
      >
        {renderValue ? renderValue(value, tableRows[rowIndex]) : value}
      </div>
      /* eslint-enable */
    );
  },
  areEqual,
);

function VirtualizedTable({
  tableRows,
  tableColumns,
  headerConfig,
  width,
  height,
  rowKey,
  rowHeight = 36,
  previewColumn,
  getRowHeight,
  headerHeight = 36,
  headerTopBorder,
  headerLeftBorder,
  tableContainerStyle,
  cellPaddingLeftSize,
  overscanColumnCount = 5,
  overscanRowCount = 10,
  hoovering = true,
  customHoverClass,
  formatCellConditionally,
  handleRowRightClick,
}) {
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const outerContainerRef = useRef(null);
  const headerGridRef = useRef(null);
  const mainGridRef = useRef(null);
  const mainGridContainerRef = useRef(null);

  const contentHeight = useMemo(
    () =>
      getRowHeight
        ? tableRows.reduce((acc, _, idx) => getRowHeight(idx) + acc, 0)
        : tableRows.length * rowHeight,
    [tableRows, getRowHeight, rowHeight],
  );
  const contentWidth = useMemo(
    () => tableColumns.reduce((acc, value) => acc + value.width, 0),
    [tableColumns],
  );

  const headerRowCount = headerConfig ? headerConfig.length : 1;

  const headerData = useMemo(() => {
    if (!headerConfig) {
      return [
        tableColumns.map(col => ({
          header: col.header,
          className: col.className,
        })),
      ];
    }

    const data = [];

    for (let rowIndex = 0; rowIndex < headerConfig.length; rowIndex += 1) {
      const rowConfig = headerConfig[rowIndex];
      const rowData = [];

      let colIndex = 0;

      rowConfig.forEach(cellConfig => {
        const { header, className, colspan = 1 } = cellConfig;

        rowData[colIndex] = {
          header,
          className,
        };

        for (let i = 1; i < colspan; i += 1) {
          colIndex += 1;
          rowData[colIndex] = {
            header: '',
            className,
            isPlaceholder: true,
          };
        }

        colIndex += 1;
      });

      data.push(rowData);
    }

    return data;
  }, [headerConfig, tableColumns]);

  const headerGridData = useMemo(
    () => ({
      headerData,
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      addWidthToLastRow: contentHeight > height - headerHeight * headerRowCount,
    }),
    [
      headerData,
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      contentHeight,
      height,
      headerHeight,
      headerRowCount,
    ],
  );

  const mainGridData = useMemo(
    () => ({
      tableRows,
      tableColumns,
      cellPaddingLeftSize,
      rowClickable: !!previewColumn,
      activeRowIndex,
      setActiveRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      setHoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
    }),
    [
      tableRows,
      tableColumns,
      cellPaddingLeftSize,
      previewColumn,
      activeRowIndex,
      hoovering,
      customHoverClass,
      hoveredRowIndex,
      formatCellConditionally,
      handleRowRightClick,
    ],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: outerGrid } = outerContainerRef;

    if (outerGrid) {
      const handler = e => {
        e.preventDefault();
        const { deltaX, deltaY } = e;

        const { current: grid } = mainGridRef;
        const { current: header } = headerGridRef;
        const { current: gridDiv } = mainGridContainerRef;

        const hiddenContainer = gridDiv.firstElementChild;

        const maxVScroll = hiddenContainer.clientHeight - gridDiv.clientHeight;
        const maxHScroll = hiddenContainer.clientWidth - gridDiv.clientWidth;

        if (gridDiv && grid && header) {
          let { scrollLeft, scrollTop } = gridDiv;

          if (Math.abs(deltaY) > Math.abs(deltaX)) {
            scrollTop = scrollTop + deltaY < maxVScroll ? scrollTop + deltaY : maxVScroll;
          } else {
            scrollLeft = scrollLeft + deltaX < maxHScroll ? scrollLeft + deltaX : maxHScroll;
          }

          header.scrollTo({ scrollLeft });
          grid.scrollTo({ scrollLeft, scrollTop });
        }
      };

      outerGrid.addEventListener('wheel', handler);

      return () => outerGrid.removeEventListener('wheel', handler);
    }
  }, []);

  const onScroll = useCallback(({ scrollLeft, scrollUpdateWasRequested }) => {
    if (!scrollUpdateWasRequested && headerGridRef.current) {
      headerGridRef.current.scrollTo({ scrollLeft, scrollTop: 0 });
    }
  }, []);

  useEffect(() => {
    headerGridRef.current?.resetAfterColumnIndex(tableColumns.length - 1);
    mainGridRef.current?.resetAfterColumnIndex(tableColumns.length - 1);
  }, [width, height, tableColumns, activeRowIndex]);

  useEffect(() => {
    mainGridRef.current?.resetAfterRowIndex(0);
  }, [tableRows]);

  const getColumnWidth = useCallback(
    index => {
      if (
        index === tableColumns.length - 1 &&
        width >
          (activeRowIndex !== null && !!previewColumn
            ? contentWidth + previewColumn.width
            : contentWidth)
      ) {
        return (
          width -
          (activeRowIndex !== null && !!previewColumn ? previewColumn.width : 0) -
          contentWidth -
          (contentHeight + headerHeight > height ? SCROLLBAR_SIZE : 0) +
          tableColumns[tableColumns.length - 1].width
        );
      }

      return tableColumns[index].width;
    },
    [
      width,
      tableColumns,
      activeRowIndex,
      previewColumn,
      contentWidth,
      contentHeight,
      height,
      headerHeight,
    ],
  );

  const estimatedColumnWidth = useMemo(
    () => Math.round(contentWidth / Object.values(tableColumns).length),
    [contentWidth, tableColumns],
  );

  const estimatedRowHeight = useMemo(
    () => Math.round(contentHeight / tableRows.length),
    [contentHeight, tableRows],
  );

  return (
    <div className="d-flex">
      <div ref={outerContainerRef} onMouseLeave={() => setHoveredRowIndex(null)}>
        <VariableSizeGrid
          itemData={headerGridData}
          ref={headerGridRef}
          className="bo-table-header-container"
          columnCount={tableColumns.length}
          columnWidth={index => getColumnWidth(index)}
          height={headerHeight * headerRowCount}
          rowCount={headerRowCount}
          rowHeight={() => headerHeight}
          width={activeRowIndex !== null && !!previewColumn ? width - previewColumn.width : width}
          estimatedColumnWidth={estimatedColumnWidth}
          estimatedRowHeight={headerHeight}
          overscanColumnCount={overscanColumnCount}
        >
          {HeaderCell}
        </VariableSizeGrid>
        <VariableSizeGrid
          itemKey={({ columnIndex, data, rowIndex }) =>
            `${data.tableRows[rowIndex][rowKey]}-${columnIndex}`
          }
          className="bo-table-container"
          style={tableContainerStyle}
          ref={mainGridRef}
          outerRef={mainGridContainerRef}
          itemData={mainGridData}
          onScroll={onScroll}
          columnCount={tableColumns.length}
          columnWidth={index => getColumnWidth(index)}
          height={height - headerHeight * headerRowCount}
          rowCount={tableRows.length}
          rowHeight={getRowHeight || (() => rowHeight)}
          width={activeRowIndex !== null && !!previewColumn ? width - previewColumn.width : width}
          estimatedColumnWidth={estimatedColumnWidth}
          estimatedRowHeight={estimatedRowHeight}
          overscanColumnCount={overscanColumnCount}
          overscanRowCount={overscanRowCount}
        >
          {Cell}
        </VariableSizeGrid>
      </div>
      {activeRowIndex !== null && !!previewColumn && (
        <div style={{ width: previewColumn.width, height }}>
          <div
            style={{
              width: previewColumn.width,
              height: headerHeight,
            }}
            className={`bo-table-cell fw-bold px-1 overflow-hidden border-bottom border-start ${
              headerTopBorder ? 'border-top' : ''
            }`}
          >
            {previewColumn.header}
          </div>
          <div
            style={{ width: previewColumn.width, height: height - headerHeight }}
            // eslint-disable-next-line max-len
            className="bo-table-cell bo-table-container align-items-start overflow-auto border-start"
          >
            {previewColumn.renderValue
              ? previewColumn.renderValue(
                  tableRows[activeRowIndex][previewColumn.key],
                  tableRows[activeRowIndex],
                  height - headerHeight,
                )
              : tableRows[activeRowIndex][previewColumn.key]}
          </div>
        </div>
      )}
    </div>
  );
}

export default VirtualizedTable;
